import React from "react";
import {Box, styled, SxProps, Theme, useTheme} from "@mui/material";
import {Staff} from "types/Config/userManagerTypes";
import SortIcon from "components/Svg/SortIcon";
import ClipboardCopyIcon from "components/Svg/ClipboardCopyIcon";
import ClipboardCopySuccessIcon from "components/Svg/ClipboardCopySuccessIcon";
import DeededResultsNotFound from "v2/components/DeededResultsNotFound";
import DeededCircularLoader from "v2/components/DeededCircularLoader";
import useTypedSelector from "utils/hooks/useTypedSelector";
import UserManagerEditBlockForItems from "./UserManagerEditBlockForItems";
import UserManagerLastResetPasswordTableItem from "./UserManagerLastResetPasswordTableItem";
import UserManagerMfaTableItem from "./UserManagerMfaTableItem";

interface UserManagerListClientsProps {
  data: Staff[];
  handleUser: (user: Staff) => void;
  sx: SxProps<Theme>;
  selectedSortValue: "desc" | "asc";
  selectedSort: string;
  changeSort: (orderby: string, role: string) => void;
  copiedToClipboard: boolean;
  handleClipboardCopy: (email: string) => void;
  searchVal: string;
}

const UserManagerListClients: React.FC<UserManagerListClientsProps> = ({
  data,
  handleUser,
  sx,
  selectedSortValue,
  selectedSort,
  changeSort,
  copiedToClipboard,
  handleClipboardCopy,
  searchVal,
}) => {
  const userManagerResultLoading = useTypedSelector(
    (state) => state.ConfigReducer.userManagerReducer.configLoader,
  );

  const theme = useTheme();

  return (
    <Box sx={sx}>
      <TableHeader className="TableHeader">
        <Box className="TableHeaderCell">
          Name
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "full_name"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("full_name", "clients");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          Email
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "email"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("email", "clients");
              }}
            />
          </div>
        </Box>
        <Box className="TableHeaderCell">
          Date Created
          <div className="chevronsBox">
            <SortIcon
              className={
                selectedSortValue === "asc" && selectedSort === "date_created"
                  ? "active"
                  : ""
              }
              onClick={() => {
                changeSort("date_created", "clients");
              }}
            />
          </div>
        </Box>
      </TableHeader>
      <Box
        sx={{
          "& .TableBodyCell": {
            [theme.breakpoints.down("lg")]: {
              "&:nth-of-type(3)>.ItemWrapper": {
                "&::before": {
                  content: '"Created: "',
                },
              },
            },
          },
        }}
      >
        {userManagerResultLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <DeededCircularLoader />
          </Box>
        ) : data.length !== 0 ? (
          data.map((item) => (
            <TableRowWrapper key={item.id} className="TableRowWrapper">
              <>
                <Box className="TableBodyCell">
                  {/* TODO Removed userpics to reduce the amount of requests to the server, until we solve the DB connections issue */}
                  {/*<div className="ImageWrapper">*/}
                  {/*  {item.userpic ? (*/}
                  {/*    <img*/}
                  {/*      src={`${baseURL}/userpics/${*/}
                  {/*        item?.userpic.id*/}
                  {/*      }?t=${Date.now()}`}*/}
                  {/*      alt="avatar"*/}
                  {/*    />*/}
                  {/*  ) : (*/}
                  {/*    <DefaultPhotoIcon />*/}
                  {/*  )}*/}
                  {/*</div>*/}
                  <Box
                    sx={{
                      [theme.breakpoints.down("xl")]: {
                        maxWidth: "142px !important",
                      },
                      [theme.breakpoints.down("lg")]: {
                        maxWidth: "180px !important",
                      },
                    }}
                    className="ItemWrapper"
                    title={item.full_name}
                  >
                    {item.full_name}
                  </Box>
                </Box>
                <Box className="TableBodyCell email">
                  {item.email && (
                    <div className="CopyEmailPrompt">
                      {item.email} <div className="Triangle" />
                      {!copiedToClipboard ? (
                        <ClipboardCopyIcon
                          onClick={() =>
                            handleClipboardCopy(item.email as string)
                          }
                        />
                      ) : (
                        <ClipboardCopySuccessIcon />
                      )}
                    </div>
                  )}
                  <div className="ItemWrapper" title={item.email}>
                    {item.email}
                  </div>
                </Box>
                <Box className="TableBodyCell">
                  <div className="ItemWrapper" title={item.date_created}>
                    {item.date_created}
                  </div>
                </Box>
                <UserManagerMfaTableItem item={item} />
                <UserManagerLastResetPasswordTableItem item={item} />
                <Box className="TableBodyCell UserManagerUserTools">
                  <UserManagerEditBlockForItems
                    handleUser={() => handleUser(item)}
                    userId={item.id}
                    isDeleted={!!item.deleted_at}
                  />
                </Box>
              </>
            </TableRowWrapper>
          ))
        ) : (
          <DeededResultsNotFound
            searchVal={searchVal}
            textNotFound="There are no users yet"
          />
        )}
      </Box>
    </Box>
  );
};
const gridTemplateColumns = "1fr 1fr 15% 5% 1fr  100px";
const TableHeader = styled(Box)({
  gridTemplateColumns: gridTemplateColumns,
});

const TableRowWrapper = styled(Box)({
  gridTemplateColumns: gridTemplateColumns,
});

export default UserManagerListClients;
