import React from "react";
import Select from "react-select";

export const provincesByTimezone = [
  {id: "America/Edmonton", name: "Alberta (AB)"},
  {id: "America/Vancouver", name: "British Columbia (BC)"},
  {id: "America/Winnipeg", name: "Manitoba (MB)"},
  {id: "America/Moncton", name: "New Brunswick (NB)"},
  {id: "America/Goose_Bay", name: "Newfoundland & Labrador (NL)"},
  {id: "America/Yellowknife", name: "Northwest Territories (NT)"},
  {id: "America/Halifax", name: "Nova Scotia (NS)"},
  {id: "America/Resolute", name: "Nunavut (NU)"},
  {id: "America/Toronto", name: "Ontario (ON)"},
  {id: "America/Halifax", name: "Prince Edward Island (PE)"},
  {id: "America/Montreal", name: "Quebec (QC)"},
  {id: "America/Regina", name: "Saskatchewan (SK)"},
  {id: "America/Whitehorse", name: "Yukon(YT)"},
];

// Canada provinces timeZones Daylight Saving Time (DST) format in 2021
// https://www.countries-ofthe-world.com/time-zones-canada.html
export const provincesTimeZones = [
  {label: "Standard Time", value: "Standard Time Zone", key: "STZ"},
  {label: "Summer Time", value: "Daylight Saving Time", key: "DTS"},
];
export const provincesOffset = {
  DTS: [
    {label: "AB -6:00", value: "AB -6:00", offset: "-6"},
    {label: "BC -7:00", value: "BC -7:00", offset: "-7"},
    {label: "MB -5:00", value: "MB -5:00", offset: "-5"},
    {label: "NB -3:00", value: "NB -3:00", offset: "-3"},
    {label: "NL -3:00", value: "NL -3:00", offset: "-3"},
    {label: "NT -6:00", value: "NT -6:00", offset: "-6"},
    {label: "NS -3:00", value: "NS -3:00", offset: "-3"},
    {label: "NU -5:00", value: "NU -5:00", offset: "-5"},
    {label: "ON -4:00", value: "ON -4:00", offset: "-4"},
    {label: "PE -3:00", value: "PE -3:00", offset: "-3"},
    {label: "QC -4:00", value: "QC -4:00", offset: "-4"},
    {label: "SK -6:00", value: "SK -6:00", offset: "-6"},
    {label: "YT -7:00", value: "YT -7:00", offset: "-7"},
  ],

  STZ: [
    {label: "AB -7:00", value: "AB -7:00", offset: "-7"},
    {label: "BC -8:00", value: "BC -8:00", offset: "-8"},
    {label: "MB -6:00", value: "MB -6:00", offset: "-6"},
    {label: "NB -4:00", value: "NB -4:00", offset: "-4"},
    {label: "NL -4:00", value: "NL -4:00", offset: "-4"},
    {label: "NT -7:00", value: "NT -7:00", offset: "-7"},
    {label: "NS -4:00", value: "NS -4:00", offset: "-4"},
    {label: "NU -6:00", value: "NU -6:00", offset: "-6"},
    {label: "ON -5:00", value: "ON -5:00", offset: "-5"},
    {label: "PE -4:00", value: "PE -4:00", offset: "-4"},
    {label: "QC -5:00", value: "QC -5:00", offset: "-5"},
    {label: "SK -7:00", value: "SK -7:00", offset: "-7"},
    {label: "YT -7:00", value: "YT -7:00", offset: "-7"},
  ],
};

export const provincesList = [
  {label: "AB", value: "AB"},
  {label: "BC", value: "BC"},
  {label: "MB", value: "MB"},
  {label: "NB", value: "NB"},
  {label: "NL", value: "NL"},
  {label: "NT", value: "NT"},
  {label: "NS", value: "NS"},
  {label: "NU", value: "NU"},
  {label: "ON", value: "ON"},
  {label: "PE", value: "PE"},
  {label: "QC", value: "QC"},
  {label: "SK", value: "SK"},
  {label: "YT", value: "YT"},
];

export const ReusableSelect = ({
  disabled = false,
  inputError = false,
  defaultStyles = true,
  options,
  onChangeHandler,
  isSearchable = true,
  defaultValue = {label: "", value: ""},
  overrideStyles = false,
  className = "",
}) => {
  return (
    <>
      <Select
        isSearchable={isSearchable}
        isDisabled={disabled}
        className={`${inputError ? "input-error" : ""} ${className}`}
        styles={
          overrideStyles
            ? specialStyles
            : defaultStyles
            ? firstStyles
            : secondStyles
        }
        options={options}
        isOptionDisabled={(option) => option.disabled}
        value={defaultValue}
        defaultValue={defaultValue}
        onChange={(e) => onChangeHandler(e)}
      />
      {inputError === true ? (
        <span className="input-error-text">Field is required</span>
      ) : (
        <span style={{visibility: "hidden"}}>Field is required</span>
      )}
    </>
  );
};

const sourceStyles = {
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  placeholder: (base) => ({
    ...base,
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0",
  }),
  control: () => ({
    height: "40px",
    display: "flex",
    alignItems: "center",
  }),
  container: (base) => ({
    ...base,
    width: "100%",
    height: "43px",

    border: "1px solid #e3e3e3",
    borderRadius: "5px",
    background: "#fff",
  }),
};

const firstStyles = {
  ...sourceStyles,
  container: (base) => ({
    ...base,
    width: "100%",
    height: "40px",
    border: "1px solid #e3e3e3",
    borderRadius: "5px",
    background: "#fff",
    position: "relative",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10,
    position: "absolute",
  }),
  menuList: (base) => ({
    ...base,
    height: "130px",
    zIndex: 1000,
  }),
  input: (base) => ({
    ...base,
    padding: "0",
    paddingLeft: "1rem",
  }),
  singleValue: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    padding: "0",
    top: "50%",
    paddingLeft: "1rem",
  }),
};
const secondStyles = {
  ...sourceStyles,
  container: (base) => ({
    ...base,
    width: "100%",
    height: "40px",
    border: "1px solid #e3e3e3",
    borderRadius: "5px",
    background: "#fff",
    position: "relative",
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10,
    position: "absolute",
  }),
  menuList: (base) => ({
    ...base,
    height: "120px",
    zIndex: 1000,
    "&::-webkit-scrollbar": {
      width: "5px",
      scrollbarWidth: "thin",
    },
  }),
  input: (base) => ({
    ...base,
    marginBottom: "10px",
    padding: "0",
    paddingLeft: "1rem",
  }),
  singleValue: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    padding: "0",
    top: "50%",
    paddingLeft: "1rem",
  }),
};

const specialStyles = {...firstStyles, ...sourceStyles};
