// Roles
export const isClient = (role: string = '') => {
  return [
    'Client',
    'Secondary Client',
  ].includes(role);
};

export const isAdmin = (role: string = "") => ["Admin", "System Admin"].includes(role);

export const isStaff = (role: string | undefined, skipRoles: string[] = []) => {
    return [
        'Admin',
        'System Admin',
        'Lawyer',
        'Law Clerk',
        'Mobile Signer',
        'Closing Advisor',
        'Access',
        'File Opener',
        'Document Support Specialist'
    ]
        .filter(r => !skipRoles.includes(r))
        .includes(role || '');
}

export const isProfessional = (role: string | undefined) => {
    return [
        'Realtor',
        'Real Estate/Mortgage Team',
        'Broker',
        'Lender',
        'Company Admin'
    ].includes(role || '');
}

export const isLawFirm = (role: string | undefined) => {
    return [
        'Law Firm'
    ].includes(role || '');
}

export const getUserType = (role?: string) => {
    if (isStaff(role)) return 'staff';
    if (isProfessional(role)) return 'professional';
    return 'client';
}

// Permissions
export const shouldCreateNewDealClient = (role: string = '') => {
    return ![
        'Lawyer',
        'Law Clerk',
        'Closing Advisor',
        'Document Support Specialist',
        'Client',
        'Secondary Client'
    ].includes(role);
};

// TODO Hiding message section (DealChat) on all deals to validate its usage, before fully removal from codebase
export const shouldShowDealMessages = (role: string = '') => false;

export const shouldShowLenderInfo = (role: string) =>
    ![
        "Client",
        "Secondary Client",
        "Realtor",
        "Real Estate/Mortgage Team",
        "Broker",
        "Lender",
        "Mobile Signer",
        "Company Admin"
    ].includes(role);

export const shouldBeAskedTOS = (role: string) => {
  return [
    'Client',
    'Secondary Client',
    'Realtor',
    'Real Estate/Mortgage Team',
    'Broker',
    'Lender',
    'Company Admin'
  ].includes(role);
}

export const shouldShowStorageInSidebar = (role: string = "") =>
    ["Admin", "System Admin", "Document Support Specialist"].includes(role);

export const shouldShowCalendarInSidebar = (role: string = "") =>
    isStaff(role, ["Access", "File Opener", "Closing Advisor"]);

export const shouldShowCalendarBlockTime = (role: string = "") => ["System Admin", "Lawyer"].includes(role);

export const shouldShowPreferences = (role: string = "") => isStaff(role, ["Access", "File Opener"]);

export const shouldCancelDeal = (role: string = "") => isStaff(role, ["Mobile Signer"]);

export const shouldReopenDeal = (role: string = "") => isStaff(role, ["Law Clerk", "Mobile Signer"]);